import React from "react"
import * as style from "../styles/quality.module.css"
import Layout from "../components/layout/Layout"
import { Helmet} from "react-helmet";
import { graphql, navigate } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Button } from "../assets/Button"

import Data from "../data/layout/navbar.json"
import pdfIcon from "../images/assets/pdf3.svg"
//Certificados
import ISO9001PDF from "../assets/certificados/GRUPO GOF ISO 9001 validez hasta 23.01.27.pdf"
import ISO14001PDF from "../assets/certificados/GRUPO GOF ISO 14001 validez hasta 23.01.27.pdf"
import ISO45001PDF from "../assets/certificados/GRUPO GOF ISO 45001 validez hasta 23.01.27.pdf"
//Documentos
import CodigoEtico from "../assets/documentos/Código Ético Grupo GOF.pdf"
import Politicas from "../assets/documentos/Políticas Grupo GOF.pdf"

//Plugin i18next
import { useTranslation, Trans } from "gatsby-plugin-react-i18next"


const QualityPage = ({location, data, href}) => {
    
  const { t } = useTranslation(undefined, {keyPrefix: "Sustainability"})
  const { t: tnav } = useTranslation(undefined, {keyPrefix: "Navbar"})

  const [pagehash, setPagehash] = React.useState(location?.hash ?? "#quality");
  React.useEffect(() => {
    if (location?.hash) {
      setPagehash(location?.hash);
    }
  }, [location.hash]);
    
  const QualitySlice = () => {
    const image = getImage(data.fileSostenibilidad.childImageSharp.gatsbyImageData)
    return (
      <div className={style.middleContainer + " " + style.normative} style={
        (pagehash && pagehash !== "#quality") ? {
          opacity: 0, height: 0, overflow: "hidden", position: "absolute", top: 0, left: 0, zIndex: -1
        } : undefined}>
        <div>
          <h2>{t("title")}</h2>
          <p>{t("text1")}</p>
        </div>
        <div data-type="picture" style={{width: "100%", height: 400 }}>
          <GatsbyImage image={image} alt={t("title")} placeholder="blurred"
          layout="fullWidth" style={{bottom: "-4%", top: 0}}/>
        </div>
      </div>
    )
  }

  const NormativeSlice = () => {
    const image = getImage(data.fileCumplimiento.childImageSharp.gatsbyImageData)
    return (
      <div className={style.middleContainer + " " + style.normative} style={
        pagehash !== "#normative" ? {
          opacity: 0, height: 0, overflow: "hidden", position: "absolute", top: 0, left: 0, zIndex: -1
        } : undefined}>
        <div>
          <h2>{t("subTitle1")}</h2>
          <p><Trans i18nKey="text2" t={t} components={{ Link1: <a href={CodigoEtico} target="_blank" rel="noreferrer"></a> }} /></p>
          <Button style={{marginTop: 10, color: "white", display: "block", width: "fit-content"}} link={CodigoEtico} isLink={false}>
            {t("link1")}<div style={PdfIcon}/>
          </Button>
        </div>
        <div data-type="picture" style={{width: "100%", height: 400 }}>
          <GatsbyImage image={image} alt={t("subTitle1")} placeholder="blurred"
          layout="fullWidth" style={{bottom: "-4%", top: 0}}/>
        </div>
      </div>
    );
  }

  const SecuritySlice = () => {
    const image = getImage(data.fileSeguridad.childImageSharp.gatsbyImageData)
    return (
      <div className={style.middleContainer + " " + style.normative} style={
        pagehash !== "#security" ? {
          opacity: 0, height: 0, overflow: "hidden", position: "absolute", top: 0, left: 0, zIndex: -1
        } : undefined}>
        <div>
          <h2>{t("subTitle2")}</h2>
          <p>{t("text3")}</p>
        </div>
        <div data-type="picture" style={{width: "100%", height: 400 }}>
          <GatsbyImage image={image} alt={t("subTitle2")} placeholder="blurred"
          layout="fullWidth" style={{bottom: "-4%", top: 0}}/>
        </div>
      </div>
    );
  }

  const OperativeSlice = () => {
    const image = getImage(data.fileOperativa.childImageSharp.gatsbyImageData)
    return (
      <div className={style.middleContainer + " " + style.normative} style={
        pagehash !== "#operative" ? {
          opacity: 0, height: 0, overflow: "hidden", position: "absolute", top: 0, left: 0, zIndex: -1
        } : undefined}>
        <div>
          <h2>{t("subTitle3")}</h2>
          <p>{t("text4")}</p>
        </div>
        <div data-type="picture" style={{width: "100%", height: 400 }}>
          <GatsbyImage image={image} alt={t("subTitle3")} placeholder="blurred"
          layout="fullWidth" style={{bottom: "-4%", top: 0}}/>
        </div>
      </div>
    );
  }

  const EnviromentalSlice = () => {
    const image = getImage(data.fileMedioAmbiente.childImageSharp.gatsbyImageData)
    return (
      <div className={style.middleContainer + " " + style.normative} style={
        pagehash !== "#environment" ? {
          opacity: 0, height: 0, overflow: "hidden", position: "absolute", top: 0, left: 0, zIndex: -1
        } : undefined}>
        <div>
          <h2>{t("subTitle4")}</h2>
          <p><Trans i18nKey="text5" t={t} components={{ Link1: <a href={Politicas} target="_blank" rel="noreferrer"></a> }} /></p>
          <Button style={{marginTop: 10, color: "white", display: "block", width: "fit-content"}} link={Politicas} isLink={false}>
            {t("link2")}<div style={PdfIcon}/>
          </Button>
        </div>
        <div data-type="picture" style={{width: "100%", height: 400 }}>
          <GatsbyImage image={image} alt={t("subTitle1")} placeholder="blurred"
          layout="fullWidth" style={{bottom: "-4%", top: 0}}/>
        </div>
      </div>
    );
  }

  const CertificatesSlice = () => {
    const cert9001 = getImage(data.cert9001.childImageSharp.gatsbyImageData)
    const cert14001 = getImage(data.cert14001.childImageSharp.gatsbyImageData)
    const cert45001 = getImage(data.cert45001.childImageSharp.gatsbyImageData)
    return (
      <div className={style.middleContainer + " " + style.certificates} style={
        pagehash !== "#certificates" ? {
          opacity: 0, height: 0, overflow: "hidden", position: "absolute", top: 0, left: 0, zIndex: -1
        } : undefined}>
        <h2>{t("subTitle5")}</h2>
        <p>{t("text6")}</p>
        <div className={style.certificatesSub}>
          <div>
            <a type="application/pdf" href={ISO9001PDF} target="_blank" rel="noreferrer">
              <GatsbyImage image={cert9001} alt="ISO 9001" placeholder="tracedSVG" />
            </a>
          </div>
          <div>
            <a type="application/pdf" href={ISO14001PDF} target="_blank" rel="noreferrer">
              <GatsbyImage image={cert14001} alt="ISO 14001" placeholder="tracedSVG" />
            </a>
          </div>
          <div>
            <a type="application/pdf" href={ISO45001PDF} target="_blank" rel="noreferrer">
              <GatsbyImage image={cert45001} alt="ISO 45001" placeholder="tracedSVG" />
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Layout href="../" link="./">
      <div className={style.container}>
        <div data-type="nav" className={style.middleContainer} style={{marginBottom: "20px"}}>
          <ul>
            { Data[3].dropdown.map((data, index) => {
              return (
                <li key={data.nombre}>
                  <a onClick={() => navigate("../"+data.link)} data-type="link" data-active={(
                    data.link === "qualityPage"+pagehash || (index === 0 && !pagehash)
                    ) ? "true" : "false"}>
                    {tnav(data.nombre)}
                  </a>
                </li>
              )}) 
            }
          </ul>
        </div>
        <QualitySlice/>
        <NormativeSlice/>
        <SecuritySlice/>
        <OperativeSlice/>
        <EnviromentalSlice/>
        <CertificatesSlice/>
      </div>
    </Layout>
  )
}

export default QualityPage

const PdfIcon = {
  backgroundImage: `url(${pdfIcon})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: "contain",
  width: 22
}

export function Head() {
  
    return (
      <>
        <Helmet>
            <html lang='es'/>
            <title>GRUPO GOF</title>
            <meta name="description" content="Página desarrollada por Nortic" />
            <link rel="icon" href="https://grupogof.com/wp-content/uploads/2018/10/icono-300x300.png" type="image/png"></link>
        </Helmet>
      </>
    )
  }

  export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    fileSostenibilidad: file(relativePath: { eq: "sostenibilidad/01_Sostenibilidad.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [WEBP]
        )
      }
    }
    fileCumplimiento: file(relativePath: { eq: "sostenibilidad/02_CumplimientoNormativo.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [WEBP]
        )
      }
    }
    fileSeguridad: file(relativePath: { eq: "sostenibilidad/03_SeguridadPersonas.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [WEBP]
        )
      }
    }
    fileOperativa: file(relativePath: { eq: "sostenibilidad/04_SeguridadAlimentaria.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [WEBP]
        )
      }
    }
    fileMedioAmbiente: file(relativePath: { eq: "sostenibilidad/05_MedioAmbiente.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [WEBP]
        )
      }
    }

    cert9001: file(relativePath: { eq: "assets/certificados/ISO9001-CMYK.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: TRACED_SVG
          formats: [WEBP]
          width: 200
          height: 200
        )
      }
    }
    cert14001: file(relativePath: { eq: "assets/certificados/ISO14001-CMYK.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: TRACED_SVG
          formats: [WEBP]
          width: 200
          height: 200
        )
      }
    }
    cert45001: file(relativePath: { eq: "assets/certificados/ISO45001-CMYK.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: TRACED_SVG
          formats: [WEBP]
          width: 200
          height: 200
        )
      }
    }
  }
`;